/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('assets/fonts/poppins/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2') format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('assets/fonts/poppins/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('assets/fonts/poppins/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* TRADA */

@font-face {
  font-family: 'Trada';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('assets/fonts/trada-sans/TradaSans-Book.ttf') format('truetype');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Trada';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('assets/fonts/trada-sans/TradaSans-Book.ttf') format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Trada';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('assets/fonts/trada-sans/TradaSans-Book.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

p,
div,
button {
  font-family: 'Trada';
}

.bg {
  background: #f3f3f3;
  --background: #f3f3f3;
}

.bg-2 {
  background: #447cf4;
  --background: #447cf4;
}

.box-shadow {
  -webkit-box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

.box-shadow-all {
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
}

.app-header {
}

.app-indeterminate {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.app-load {
  width: 20px;
  height: 20px;
}

.app-spinner {
  position: fixed;
  width: 30px;
  height: 30px;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  z-index: 10000;
}

.p-hiper {
  margin: auto;
  font-size: 35px !important;
  color: #000000;
}

.p-mega {
  margin: auto;
  font-size: 30px !important;
  color: #000000;
}

.p-super {
  margin: auto;
  font-size: 25px !important;
  color: #000000;
}

.p-ultra {
  margin: auto;
  font-size: 20px !important;
}

.p-title {
  margin: auto;
  font-size: 16px !important;
  color: #000000;
}

.p-subtitle {
  margin: auto;
  font-size: 14px !important;
  color: #000000;
}

.p-normal {
  margin: auto;
  font-size: 12px !important;
  color: #000000;
}

.p-info {
  margin: auto;
  font-size: 10px !important;
  color: #000000;
}

.app-button {
  height: 40px;
  max-width: 220px;
}

.app-input {
  --background: #ffffff;
  background: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: solid 1px #ffffff;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.app-input-success {
  border-color: #447cf4;
}

.app-input-danger {
  border-color: #ff0000;
}

.app-input-error {
  color: #ff0000;
  margin: auto !important;
  padding-top: 10px;
  font-size: 10px !important;
  padding-left: 10px;
}

.p-header-title {
  margin: auto;
  font-size: 20px !important;
  color: #447cf4;
}

.header-title {
  margin: auto;
  font-size: 20px !important;
  color: #447cf4;
  font-weight: bold;
}

.select-icon {
  display: none !important;
}

app-welcome .swiper-pagination {
  position: fixed !important;
  bottom: 60px !important;
}

.modal-help {
  --background: none;
}

.modal-alert {
  --background: none;
}

.modal-photo {
  --background: none;
}

.modal-commissions {
  --background: none;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  color: #666;
  opacity: 1;
  display: block;
  background: url(./assets/icon/calendar-clock.png) no-repeat;
  background-size: cover;
  width: 20px;
  height: 20px;
  border-width: thin;
}

/*
input[type='month']::-webkit-inner-spin-button,
input[type='month']::-webkit-calendar-picker-indicator {
  color: #666;
  opacity: 1;
  display: block;
  background: url(./assets/icon/calendar-clock.png) no-repeat;
  background-size: cover;
  width: 20px;
  height: 20px;
  border-width: thin;
  position: absolute;
  left: -3px;
}
*/

input[type='month']::-webkit-inner-spin-button,
input[type='month']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

body.camera-preview-active {
  --background: transparent;
  background: transparent none !important;

  ion-app,
  ion-app ion-header,
  ion-app ion-content,
  ion-app ion-footer {
    --background: transparent;
    --ion-background-color: transparent;
    background: transparent none !important;

    .camera-hidden {
      display: none;
    }
  }
}
